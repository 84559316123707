import axios, { AxiosResponse } from "axios"
import { FileModel, CourseModel, RegistrationModel, InternetUserModel, RoleModel, IntranetUserModel, AuthModel, AlertMessageModel } from "../types"
import { FoodHandlerStatus } from "../types/enum/foodhandler-status"
import { errorLogging, notify } from "../util"
import { ApiUrl, NotifyType, SessionKey } from "../util/app-config"
import { } from 'file-saver';
import Moment from 'moment';
import { RandomUUIDOptions } from "crypto"
import { RegistrationDetailModel } from "../types/registrationDetail"
import { PhotoValidationResult } from "../types/phtoModel"
import { useContext } from "react"
import { AppContext } from "../contexts"
import { MeatRegistrationModel } from "../types/Meatregistration"
//import { MeatRegistrationInfoModel } from "../types/MeatRegistrationInfoModel"
import { RegistrationEnquiryModel } from "../types/registrationEnquiry"

export const useApiService = () => {

    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const API_KEY: string = process.env.REACT_APP_API_KEY ?? '';
    const appContext = useContext(AppContext);
    const userId = appContext.internetUser?.id ?? 0;

    const token = localStorage.getItem(SessionKey.InternetAuthToken)
    const headers = {
        'Content-Type': 'application/json',
        'ApiKey': API_KEY,
        'Authorization': `Bearer ${token}`
    }

    const captchaheaders = {
        'Content-Type': 'application/json',
        'ApiKey': API_KEY
    }

    const fileHeadersPost = {
        'Content-Type': 'multipart/form-data',
        'ApiKey': API_KEY,
        'Authorization': `Bearer ${token}`
    }

    const { handleHttpError } = errorLogging();
    const { message } = notify();

    const saveRegistration = async (registration: RegistrationModel): Promise<RegistrationModel | null> => {
        let response: AxiosResponse<RegistrationModel> | void;
        registration.status = FoodHandlerStatus.Pending;
        registration.statusText = FoodHandlerStatus[FoodHandlerStatus.Pending];
        registration.dob = new Date(Moment(registration.dob).format('YYYY-MM-DD'));
        response = await axios.post(`${SERVER_URL}${ApiUrl.FoodHandler}?userId=${userId}`, registration, {
            headers: headers
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status === 200) {
            return response.data;
        } else {
            message(NotifyType.Info, "Registration Failed")
            return null;
        }
    }

    const updateRegistration = async (registration: RegistrationEnquiryModel): Promise<void> => {
        let response: AxiosResponse<RegistrationEnquiryModel> | void;
        response = await axios.put(`${SERVER_URL}${ApiUrl.FoodHandler}/UpdateFoodHandler?userId=${userId}`, registration, {
            headers: headers
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status !== 200) {
            message(NotifyType.Info, "Registration Failed")
        }
    }

    const updateMeatRegistration = async (registration: MeatRegistrationModel): Promise<void> => {
        let response: AxiosResponse<MeatRegistrationModel> | void;
        response = await axios.put(`${SERVER_URL}${ApiUrl.FoodHandler}/UpdateFoodHandler?userId=${userId}`, registration, {
            headers: headers
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status !== 200) {
            message(NotifyType.Info, "Registration Failed")
        }
    }

    const updateRegistrationStatus = async (registration: RegistrationModel): Promise<void> => {
        let response: AxiosResponse<RegistrationModel> | void;
        response = await axios.put(`${SERVER_URL}${ApiUrl.UpdateFoodHandlerStatus}`, registration, {
            headers: headers
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status !== 200) {
            message(NotifyType.Info, "Status Change Failed")
        }
    }

    const loginInternetUser = async (internetUser: InternetUserModel, role: RoleModel): Promise<InternetUserModel | null> => {
        let response: AxiosResponse<InternetUserModel> | void;
        internetUser.type = role.internetUserType;
        response = await axios.post(`${SERVER_URL}${ApiUrl.User}`, internetUser, {
            headers: headers
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    }

    const saveFile = async (image: string | Blob, fileName: string | undefined): Promise<FileModel[] | null> => {
        let response: AxiosResponse<FileModel[]> | void;
        var formData = new FormData();
        formData.append("image", image, `${fileName}.jpeg`);
        response = await axios.post(`${SERVER_URL}${ApiUrl.File}`, formData, {
            headers: fileHeadersPost
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status === 200) {
            return response.data;
        } else {
            message(NotifyType.Info, "Image upload Failed")
            return null;
        }
    }

    const getFile = async (fileId: RandomUUIDOptions, fileName: string): Promise<string | null> => {
        try {
            const response = await axios.get(`${SERVER_URL}${ApiUrl.File}/${fileId}?fileName=${fileName}`, { responseType: 'blob', headers: { 'ApiKey': API_KEY, 'Authorization': `Bearer ${token}` } })
            const imageBlobURL = URL.createObjectURL(response.data);
            return imageBlobURL
        } catch {
            message(NotifyType.Info, "Image retrieve Failed")
            return null;
        }
    }
 
    const getRegistrationOnEnquiry = async (uinPassport?: string, dob?: Date): Promise<RegistrationEnquiryModel | null> => {
        let response: AxiosResponse<RegistrationEnquiryModel> | void;
        let payload;
        if (dob) {
            payload = { UINPassport: uinPassport, DateOfBirth: Moment(dob).format('YYYY-MM-DD') }
            //payload = { UINPassport: uinPassport, dob: dob.toLocaleDateString() }
        } else {
            payload = { UINPassport: uinPassport }
        }
        response = await axios.post(`${SERVER_URL}${ApiUrl.Enquery}`, payload, {
            headers: headers
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status === 200) {
            return response.data;
        }
        return null;
    }

    const getRegistrationOnEnquiryMeat = async (uinPassport?: string, dob?: Date): Promise<MeatRegistrationModel | null> => {
        let response: AxiosResponse<MeatRegistrationModel> | void;
        let payload;
        if (dob) {
            payload = { UINPassport: uinPassport, DateOfBirth: Moment(dob).format('YYYY-MM-DD') }
            //payload = { UINPassport: uinPassport, dob: dob.toLocaleDateString() }
        } else {
            payload = { UINPassport: uinPassport }
        }
        response = await axios.post(`${SERVER_URL}${ApiUrl.EnqueryMeat}`, payload, {
            headers: headers
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status === 200) {
            return response.data;
        }
        return null;
    }

    const getPublicEnquiry = async (token?: string, uinPassport?: string, dob?: Date): Promise<RegistrationModel | null> => {

        let response: AxiosResponse<RegistrationModel> | void;
        let payload = { Token: token, UINPassport: uinPassport, DateOfBirth: Moment(dob).format('YYYY-MM-DD') };
        response = await axios.post(`${SERVER_URL}${ApiUrl.PublicEnqueryInternet}`, payload, {
            headers: captchaheaders
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status === 200) {
            return response.data;
        }
        return null;
    }

    const getMeatPublicEnquiry = async (token?: string, uinPassport?: string, dob?: Date): Promise<MeatRegistrationModel | null> => {
        let response: AxiosResponse<MeatRegistrationModel> | void;
        let payload = { Token: token, UINPassport: uinPassport, DateOfBirth: Moment(dob).format('YYYY-MM-DD') };
        response = await axios.post(`${SERVER_URL}${ApiUrl.PublicMeatEnqueryInternet}`, payload, {
            headers: captchaheaders
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status === 200) {
            return response.data;
        }
        return null;
    }     

    const getPublicFile = async (token?: string, fileId?: RandomUUIDOptions, fileName?: string): Promise<string | null> => {
     
        let payload = { Token: token, FileId: fileId, FileName: fileName };
        const response = await axios.post(`${SERVER_URL}${ApiUrl.PublicGetFile}`, payload, {
            headers: captchaheaders
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status === 200) {
            const imageBlobURL = URL.createObjectURL(response.data);
            return imageBlobURL
        } else {
            message(NotifyType.Info, "Image retrieve Failed")
            return null;
        }     
    }     

    const getRegistrationById = async (id?: number,): Promise<RegistrationModel | null> => {
        let response: AxiosResponse<RegistrationModel> | void;
        response = await axios.post(`${SERVER_URL}${ApiUrl.FoodHandler}/${id}`, {}, {
            headers: headers
        }).catch(handleHttpError);
        if (response && response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    }

    const getRegistrationByIdstr = async (id?: string,): Promise<RegistrationEnquiryModel | null> => {
        let response: AxiosResponse<RegistrationEnquiryModel> | void;
        response = await axios.post(`${SERVER_URL}${ApiUrl.FoodHandler}/${id}`, {}, {
            headers: headers
        }).catch(handleHttpError);
        if (response && response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    }

    const getRegistrationDetail = async (): Promise<RegistrationDetailModel | null> => {
        let response: AxiosResponse<RegistrationDetailModel> | void;
        response = await axios.get(`${SERVER_URL}${ApiUrl.Registrationdetail}`, {
            headers: headers
        }).catch(handleHttpError);
        if (response && response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    }

    const validatetp = async (uin: string, dob: Date): Promise<boolean | null> => {
        let response: AxiosResponse<boolean> | void;
        const payload = { UINPassport: uin, DateOfBirth: Moment(dob).format('YYYY-MM-DD') }
        //const payload = {UINPassport: uin, dob: dob.toLocaleDateString()}
        response = await axios.post(`${SERVER_URL}${ApiUrl.Validatetp}`, payload, {
            headers: headers
        }).catch(handleHttpError);
        if (response && response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    }

    const loginIntranetUser = async (userName: string, password: string, newpassword?: string): Promise<AuthModel | null> => {
        let response: AxiosResponse<AuthModel> | void;
        const payload = {
            provider: 0,
            key: userName,
            secret: password,
            newSecret: newpassword
        }
        response = await axios.put(`${SERVER_URL}${ApiUrl.Auth}`, payload, {
            headers: headers
        }).catch(handleHttpError);
        if (response && response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    }

    const validateImage = async (bestPrediction: string | Blob, photo: string | Blob): Promise<PhotoValidationResult | null> => {

        let response: AxiosResponse<PhotoValidationResult> | void;
        var formData = new FormData();
        formData.append("file", bestPrediction, `bestPrediction.jpeg`);
        formData.append("file", photo, `photo.jpeg`);
        response = await axios.post(`${SERVER_URL}${ApiUrl.FileValidation}`, formData, {
            headers: fileHeadersPost
        }).catch(handleHttpError);
        if (response && response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    }

    const getAlertMessages = async (): Promise<AlertMessageModel[] | null> => {
        let response: AxiosResponse<AlertMessageModel[]> | void;
        response = await axios.get(`${SERVER_URL}${ApiUrl.Alert}`, {
            headers: headers
        }).catch(handleHttpError);
        if (response && response.status === 200) {
            return response.data;
        }
        return null;
    }

    return {
        saveRegistration: saveRegistration,
        updateRegistration: updateRegistration,
        updateMeatRegistration: updateMeatRegistration,
        updateRegistrationStatus: updateRegistrationStatus,
        getRegistrationOnEnquiry: getRegistrationOnEnquiry,
        getRegistrationOnEnquiryMeat: getRegistrationOnEnquiryMeat,
        validatetp: validatetp,
        saveFile: saveFile,
        getFile: getFile,
        loginInternetUser: loginInternetUser,
        loginIntranetUser: loginIntranetUser,
        getRegistrationById: getRegistrationById,
        getRegistrationDetail: getRegistrationDetail,
        validateImage: validateImage,
        getAlertMessages: getAlertMessages,
        getPublicEnquiry: getPublicEnquiry,
        getMeatPublicEnquiry: getMeatPublicEnquiry,
        getRegistrationByIdstr: getRegistrationByIdstr,
        getPublicFile: getPublicFile
    }
}