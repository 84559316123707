import React from "react";
import { ImageModel, InternetUserModel, IntranetUserModel, NavigationModel, RegistrationModel, RoleModel } from "../types";
import { FoodHandlerStatus } from "../types/enum/foodhandler-status";
import { MeatRegistrationModel } from "../types/Meatregistration";
//import { RegistrationEnquiryModel } from "../types/registrationEnquiry";
//import { MeatRegistrationInfoModel } from "../types/MeatRegistrationInfoModel"

export type AppContextData =
{
    registration: RegistrationModel,
    //registrationEnquiryModel: RegistrationEnquiryModel, //New
    registrationMeat: MeatRegistrationModel,
    //registrationInfoMeat: MeatRegistrationInfoModel,//New
    image: ImageModel,
    role: RoleModel,
    internetUser: InternetUserModel,
    intranetUser: IntranetUserModel,
    navigation: NavigationModel,
}

export const defaultAppContextData: AppContextData =
{
    registration: {} as RegistrationModel,
    //registrationEnquiryModel: {} as RegistrationEnquiryModel, //New
    registrationMeat: {} as MeatRegistrationModel,
    //registrationInfoMeat: {} as MeatRegistrationInfoModel, //New
    image: {} as ImageModel,
    role: {} as RoleModel,
    internetUser: {} as InternetUserModel,
    intranetUser: {} as IntranetUserModel,
    navigation: {} as NavigationModel
}

export const AppContext = React.createContext<AppContextData>(defaultAppContextData);

