import { ErrorMessage } from '@hookform/error-message';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts';
import { useApiService, useContextService } from '../../services';
import { InternetUserModel } from '../../types';
import { InternetUserType } from '../../types/enum/user-type';
import { notify } from '../../util';
import { NotifyType, PageUrl } from '../../util/app-config';

function TempLogin() {
    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const { loginInternetUser, getRegistrationOnEnquiry, getRegistrationById, getRegistrationByIdstr } = useApiService();
    const { register, handleSubmit, formState: { errors } } = useForm<InternetUserModel>();
    const [isVisiblePassword, setIsVisiblePassword] = useState<boolean>(false)
    const { putContextInternetUser, putContextRegistration, patchContextRegistration } = useContextService();
    const { message } = notify();

    const onClickLogin = async () => {
        handleSubmit(async (internetUserModel: InternetUserModel) =>
        {
            const result = await loginInternetUser(internetUserModel, appContext.role);
            if (result) {
                await putContextInternetUser(result);
                if ((appContext.internetUser.type == InternetUserType.FoodHandler) || (appContext.internetUser.type == InternetUserType.MeatHandler)
                    || (appContext.internetUser.type == InternetUserType.BusinessOwner) || (appContext.internetUser.type == InternetUserType.TrainingProvider))
                {
                    const regOnEnquirey = await getRegistrationOnEnquiry(internetUserModel.uin);
                    if (regOnEnquirey)
                    {
                        const regOnId = await getRegistrationByIdstr(regOnEnquirey.id)
                        if (regOnId) {
                            await putContextRegistration(regOnId, true);
                            navigate(PageUrl.Registration)
                        }
                    }
                    else
                    {
                        await patchContextRegistration(internetUserModel.uin);
                        //navigate(PageUrl.Registration) // Food handler
                        navigate(PageUrl.Company) // Business Owner
                        //navigate(PageUrl.Enquiry) // Public Enquiry
                    }
                } else
                {
                    navigate(PageUrl.Enquiry)
                }
            } else {
                message(NotifyType.Error, "User Login Failed")
            }
        })()
    }

    const onClickEyeTogglePasswordVisibility = () => {
        setIsVisiblePassword(isVisiblePassword ? false : true)
    }

    return (
        <div className="bg-img1 logn-bx">
            <div className="login-content">
                <img src="/ClientApp/build/Images/logo_FHD2_Hub.png" alt="" className="img-fluid" />
                <h4>Temp Login Account</h4>
                <form>
                    <div className="form-group">
                        <i className="fa fa-user login-icon" aria-hidden="true"></i>
                        <input type="text" className="form-control" placeholder="Username" id="uin" {...register('uin', { required: 'Please enter Username' })} />
                        <ErrorMessage errors={errors} name="uin" render={({ message }) => <p className="errorMsg">{message}</p>} />
                    </div>
                    <div className=" form-group">
                        <img src="/ClientApp/build/Images/lock-icon.png" alt="" className="login-icon" width="14px" />
                        <input type={isVisiblePassword ? "text" : "password"} className="form-control" placeholder="Password" id="name" {...register('name', { required: 'Please enter Password' })} />
                        <ErrorMessage errors={errors} name="name" render={({ message }) => <p className="errorMsg">{message}</p>} />
                        <i className={isVisiblePassword ? "fa fa-eye" : "fa fa-eye-slash"} id="eye" onClick={onClickEyeTogglePasswordVisibility}></i>
                    </div>
                    <button type="button" className="btn primary-btn w-100  mb-3" onClick={onClickLogin}>Login</button>
                </form>
            </div>
        </div>
    );
}

export default TempLogin;